#kontakt-container {
  width: 100%;
  background-color: #463c78;
  background-image: url("../../assets/kontakt-background.png");
  background-size: 100% 100%;
}

#kontakt-container h1 {
  color: #fbbb25;
  font-size: 30px;
  font-weight: 800;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  margin-bottom: 42px;
}

@media only screen and (min-width: 1280px) {
  #kontakt-container h1 {
    margin-bottom: 100px;
    font-size: 42px;
  }
}

#kontakt-container .kontakt-inner-wrapper {
  padding-top: 42px;
  padding-bottom: 79px;
}

@media only screen and (min-width: 1280px) {
  #kontakt-container .kontakt-inner-wrapper {
    min-height: calc(100vh - 290px);
  }
}

#kontakt-container .kontakt-inner-wrapper .items-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 20vw;
}

@media only screen and (min-width: 1280px) {
  #kontakt-container .kontakt-inner-wrapper .items-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    padding-left: 0;
  }
}

#kontakt-container .kontakt-inner-wrapper .items-container .item-wrapper {
  margin-bottom: 79px;
  text-decoration: none;
}

@media only screen and (min-width: 1280px) {
  #kontakt-container .kontakt-inner-wrapper .items-container .item-wrapper {
    margin-bottom: 0;
  }
}

#kontakt-container .kontakt-inner-wrapper .items-container .item-wrapper:active h2 img, #kontakt-container .kontakt-inner-wrapper .items-container .item-wrapper:hover h2 img {
  -webkit-filter: drop-shadow(0 0 5px #fbbb25);
          filter: drop-shadow(0 0 5px #fbbb25);
}

#kontakt-container .kontakt-inner-wrapper .items-container .item-wrapper h2 {
  display: inline-block;
  color: #fbbb25;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1280px) {
  #kontakt-container .kontakt-inner-wrapper .items-container .item-wrapper h2 {
    font-size: 25px;
  }
}

#kontakt-container .kontakt-inner-wrapper .items-container .item-wrapper h2 img {
  width: 30px;
}

#kontakt-container .kontakt-inner-wrapper .items-container .item-wrapper .icons-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#kontakt-container .kontakt-inner-wrapper .items-container .item-wrapper .icons-wrapper a {
  display: inline-block;
}

#kontakt-container .kontakt-inner-wrapper .items-container .item-wrapper .icons-wrapper img {
  width: 30px;
  height: auto;
  margin-right: 10px;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

#kontakt-container .kontakt-inner-wrapper .items-container .item-wrapper .icons-wrapper img:active, #kontakt-container .kontakt-inner-wrapper .items-container .item-wrapper .icons-wrapper img:hover {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 0 5px #fff);
          filter: drop-shadow(0 0 5px #fff);
}

#kontakt-container .kontakt-inner-wrapper .items-container .item-wrapper p {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}

@media only screen and (min-width: 1280px) {
  #kontakt-container .kontakt-inner-wrapper .items-container .item-wrapper p {
    font-size: 20px;
  }
}
