#o-nama-container {
  background-color: #463c78;
  .section-1-container {
    padding: 42px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      max-width: 840px;
    }
  }
  .section-2-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (min-width: 1280px) {
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-around;
    }
    img {
      width: 79%;
      max-width: 500px;
      margin-top: 42px;
      margin-bottom: 42px;
    }
  }
  .section-3-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
    @media only screen and (min-width: 1280px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    img {
      width: 79%;
      max-width: 420px;
      margin-top: 42px;
      margin-bottom: 42px;
    }
  }
  .txt-wrapper {
    width: 68%;
    max-width: 420px;
    margin-top: 42px;
    margin-bottom: 42px;
    @media only screen and (min-width: 1280px) {
      max-width: 500px;
    }
  }
  h1,
  h2 {
    color: #fabc06;
    font-size: 30px;
    font-weight: 800;
    text-transform: uppercase;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
    margin-bottom: 25px;
  }
  h2 {
    font-size: 25px;
  }
  p {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: justify;
  }
}
