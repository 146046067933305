#vesti-container {
  width: 100%;
  background-color: #463c78;
  background-image: url("../../assets/background-lines.png");
  background-size: 100% 100%;
  min-height: 100vh;
  h1 {
    color: #fbbb25;
    font-size: 30px;
    font-weight: 800;
    text-transform: uppercase;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
    margin-bottom: 42px;
    @media only screen and (min-width: 1280px) {
      margin-bottom: 100px;
      font-size: 42px;
    }
  }

  .vesti-inner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 42px;
    .vest-card-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 99px;
      @media only screen and (min-width: 1280px) {
        flex-direction: row;
        width: 100%;
        max-width: 1280px;
        justify-content: space-between;
      }

      .vest-img-wrapper {
        width: 100%;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (min-width: 1280px) {
          width: 49%;
          margin-bottom: 30px;
        }
        img {
          max-width: 300px;
          max-height: 300px;
          @media only screen and (min-width: 1280px) {
            max-width: 550px;
            max-height: 550px;
          }
        }
      }

      .vest-not-img-wrapper {
        width: 100%;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media only screen and (min-width: 1280px) {
          height: 450px;
          width: 49%;
          justify-content: space-between;
        }
        h2 {
          color: #fbbb25;
          font-size: 24px;
          font-weight: 700;
          text-transform: uppercase;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
          margin-bottom: 20px;
          @media only screen and (min-width: 1280px) {
            font-size: 32px;
          }
        }
        p {
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
          margin-bottom: 20px;
          text-align: justify;
        }
        .vest-datum {
          color: #fbbb25;
        }
        .btn {
          background-color: #fbbb25;
          width: 240px;
          color: #402f6f;
          font-size: 16px;
          font-weight: 700;
          font-style: normal;
          letter-spacing: normal;
          line-height: 40px;
          text-align: center;
          border-radius: 20px;
          text-decoration: none;
          &:active,
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }
  a {
    display: inline-block;
  }
}
