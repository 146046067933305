#footer-container {
  .social-media-wrapper {
    width: 100%;
    background-color: #463c78;
    padding-top: 90px;
    padding-bottom: 30px;
    position: relative;
.social-media-inner-wrapper{
    position: absolute;
    right: 42px;
    bottom: 20px;
}

    a {
      display: inline-block;
    }
    .facebook-icon {
      margin-left: auto;
    }
    img {
      width: 30px;
      height: auto;
      margin-right: 10px;
      &:active,
      &:hover {
        cursor: pointer;
        filter: drop-shadow(0 0 5px #fff);
      }
    }
  }
  .copyright {
    width: 100%;
    background-color: #402f6f;
    font-size: 14px;
    font-weight: 500;
    line-height: 60px;
    text-align: center;
    color: #fff;
    margin: 0;
  }
}
