.admin-container {
  background-color: #fbba25;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;

  p {
    font-weight: bold;
    font-size: 2em;
    padding: 5px;
  }

  h1 {
    font-size: 24px;
    color: #402f6f;
    @media screen and (min-width: 1280px) {
      font-size: 32px;
      text-align: center;
    }
  }

  .regular-input {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px 0;
    position: relative;

    input,
    textarea {
      border-radius: 15px;
      width: 90%;
      border: none;
      -webkit-box-shadow: 5px 5px 20px 10px rgba(64, 47, 111, 0.5);
      -moz-box-shadow: 5px 5px 20px 10px rgba(64, 47, 111, 0.5);
      box-shadow: 5px 5px 20px 10px rgba(64, 47, 111, 0.5);
      text-align: center;
      padding: 10px;
      font-size: 16px;

      @media screen and (min-width: 1280px) {
        font-size: 20px;
      }

      &:focus {
        outline: none;
        box-shadow: 0 20px 40px #402f6f;
      }
    }
  }

  button,
  a {
    line-height: 40px;
    border-radius: 30px;
    cursor: pointer;
    width: 300px;
    border: none;
    background-color: #402f6f;
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: 42px;
    &:active,
    &:hover {
      text-shadow: 0 0 2px #fff;
    }
  }

  .admin-publish-buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 960px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 50px;
    }

    a {
      @media screen and (max-width: 960px) {
        width: 70%;
      }
    }
  }

  .error-message-wrapper {
    color: red;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 42px;
  }

  .photo-input {
    border: 1px solid rgba(64, 48, 111, 0.3);
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 42px;
    cursor: pointer;
    width: 300px;
    text-align: center;
    background-color: rgba(64, 48, 111, 0.3);
    font-weight: 500;
    font-size: 20px;
    margin-top: 42px;

    &:hover {
      background-color: rgba(64, 48, 111, 0.9);
      color: white;
      font-weight: 500;
    }

    input[type="file"] {
      display: none;
    }
  }

  .wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    margin-bottom: 4%;

    .editor-wrapper {
      padding: 20px;
      border: 2px solid #402f6f9d;
      width: 90%;
      margin-top: 42px;
    }
    .message-toolbar {
      border-bottom: 1px solid #c4cdd5;
      margin-bottom: 10px;
      padding: 10px 5px;
      width: 100%;
    }
    .message-editor {
      min-height: 400px;
      font-size: 1.4em;
      padding: 10px 10px;
      background-color: white;
    }
    .rdw-option-wrapper {
      min-width: 35px;
      height: 40px;
    }

    .rdw-fontsize-wrapper {
      width: 15%;
      text-align: center;
      justify-content: center;
      align-items: center;

      .rdw-dropdown-wrapper,
      .rdw-fontsize-dropdown {
        width: 100%;
        height: 40px;

        .rdw-dropdown-selectedtext {
          background-color: transparent;
          color: black;
          position: relative;
          width: 100%;
          justify-content: space-evenly;
          align-items: center;
          margin: 0;

          img {
            width: 10%;
          }

          .rdw-dropdown-carettoopen {
            display: none;
          }
          .rdw-dropdown-carettoclose {
            display: none;
          }
        }
      }
    }
  }

  .previewImage {
    max-width: 50%;
    max-height: 50%;
  }

  .sent-successufully-modal {
    position: fixed;
    z-index: 99;
    background-color: #402f6f;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      color: #fbba25;
      margin: 0;
      margin-right: 12px;
      font-size: 24px;
    }
    .checkmark {
      transform: scale(0);
      animation: showCheckmark 1s forwards ease-in-out;
    }
  }

  @keyframes showCheckmark {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1);
    }
  }

  .validation-msg {
    position: absolute;
    top: 100%;
    right: 5%;
    color: red;
    font-size: 20px;
  }

  .upload-photo-preview{
    max-width: 300px;
    max-height: 300px;
    @media screen and (min-width: 1280px) {
      max-width: 640px;
      max-height: 640px;
    }
  }
}
