#projekti-container {
  width: 100%;
  background-color: #463c78;
  background-image: url("../../assets/background-lines.png");
  background-size: 100% 100%;
  min-height: 100vh;
}

#projekti-container h1 {
  color: #fbbb25;
  font-size: 30px;
  font-weight: 800;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  margin-bottom: 42px;
}

@media only screen and (min-width: 1280px) {
  #projekti-container h1 {
    margin-bottom: 100px;
    font-size: 42px;
  }
}

#projekti-container .projekti-inner-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 42px;
}

#projekti-container .projekti-inner-wrapper .vest-card-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 99px;
}

@media only screen and (min-width: 1280px) {
  #projekti-container .projekti-inner-wrapper .vest-card-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    max-width: 1280px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

#projekti-container .projekti-inner-wrapper .vest-card-container .vest-img-wrapper {
  width: 100%;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media only screen and (min-width: 1280px) {
  #projekti-container .projekti-inner-wrapper .vest-card-container .vest-img-wrapper {
    width: 49%;
    margin-bottom: 30px;
  }
}

#projekti-container .projekti-inner-wrapper .vest-card-container .vest-img-wrapper img {
  max-width: 300px;
  max-height: 300px;
}

@media only screen and (min-width: 1280px) {
  #projekti-container .projekti-inner-wrapper .vest-card-container .vest-img-wrapper img {
    max-width: 550px;
    max-height: 550px;
  }
}

#projekti-container .projekti-inner-wrapper .vest-card-container .vest-not-img-wrapper {
  width: 100%;
  max-width: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (min-width: 1280px) {
  #projekti-container .projekti-inner-wrapper .vest-card-container .vest-not-img-wrapper {
    height: 450px;
    width: 49%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

#projekti-container .projekti-inner-wrapper .vest-card-container .vest-not-img-wrapper h2 {
  color: #fbbb25;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1280px) {
  #projekti-container .projekti-inner-wrapper .vest-card-container .vest-not-img-wrapper h2 {
    font-size: 32px;
  }
}

#projekti-container .projekti-inner-wrapper .vest-card-container .vest-not-img-wrapper p {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  margin-bottom: 20px;
  text-align: justify;
}

#projekti-container .projekti-inner-wrapper .vest-card-container .vest-not-img-wrapper .vest-datum {
  color: #fbbb25;
}

#projekti-container .projekti-inner-wrapper .vest-card-container .vest-not-img-wrapper .btn {
  background-color: #fbbb25;
  width: 240px;
  color: #402f6f;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 40px;
  text-align: center;
  border-radius: 20px;
  text-decoration: none;
}

#projekti-container .projekti-inner-wrapper .vest-card-container .vest-not-img-wrapper .btn:active, #projekti-container .projekti-inner-wrapper .vest-card-container .vest-not-img-wrapper .btn:hover {
  cursor: pointer;
  text-decoration: underline;
}

#projekti-container a {
  display: inline-block;
}
