#kontakt-container {
  width: 100%;
  background-color: #463c78;
  background-image: url("../../assets/kontakt-background.png");
  background-size: 100% 100%;
  h1 {
    color: #fbbb25;
    font-size: 30px;
    font-weight: 800;
    text-transform: uppercase;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
    margin-bottom: 42px;
    @media only screen and (min-width: 1280px) {
      margin-bottom: 100px;
      font-size: 42px;
    }
  }
  .kontakt-inner-wrapper {
    padding-top: 42px;
    padding-bottom: 79px;
    @media only screen and (min-width: 1280px) {
      min-height: calc(100vh - 290px);
    }
    .items-container {
      display: flex;
      flex-direction: column;
      padding-left: 20vw;
      @media only screen and (min-width: 1280px) {
        flex-direction: row;
        justify-content: space-around;
        padding-left: 0;
      }
      .item-wrapper {
        margin-bottom: 79px;
        text-decoration: none;
      @media only screen and (min-width: 1280px) {
        margin-bottom: 0;
      }
      &:active,
      &:hover{
        h2 img{
          filter: drop-shadow(0 0 5px #fbbb25);
        }
      }
        h2 {
          display: inline-block;
          color: #fbbb25;
          font-size: 20px;
          font-weight: 700;
          text-transform: uppercase;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
          margin-bottom: 20px;
          @media only screen and (min-width: 1280px) {
            font-size: 25px;
          }

          img {
            width: 30px;
          }
        }
        .icons-wrapper {
          display: flex;
          align-items: center;
          a {
            display: inline-block;
          }
          img {
            width: 30px;
            height: auto;
            margin-right: 10px;
            transition: transform 0.5s;
            &:active,
            &:hover {
              cursor: pointer;
              filter: drop-shadow(0 0 5px #fff);
            }
          }
        }
        p {
          font-size: 16px;
          color: #fff;
          font-weight: 500;
          @media only screen and (min-width: 1280px) {
            font-size: 20px;
          }
        }
      }
    }
  }
}
