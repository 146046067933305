.admin-container {
  background-color: #fbba25;
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 50px 0;
}

.admin-container p {
  font-weight: bold;
  font-size: 2em;
  padding: 5px;
}

.admin-container h1 {
  font-size: 24px;
  color: #402f6f;
}

@media screen and (min-width: 1280px) {
  .admin-container h1 {
    font-size: 32px;
    text-align: center;
  }
}

.admin-container .regular-input {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 20px 0;
  position: relative;
}

.admin-container .regular-input input,
.admin-container .regular-input textarea {
  border-radius: 15px;
  width: 90%;
  border: none;
  -webkit-box-shadow: 5px 5px 20px 10px rgba(64, 47, 111, 0.5);
  box-shadow: 5px 5px 20px 10px rgba(64, 47, 111, 0.5);
  text-align: center;
  padding: 10px;
  font-size: 16px;
}

@media screen and (min-width: 1280px) {
  .admin-container .regular-input input,
  .admin-container .regular-input textarea {
    font-size: 20px;
  }
}

.admin-container .regular-input input:focus,
.admin-container .regular-input textarea:focus {
  outline: none;
  -webkit-box-shadow: 0 20px 40px #402f6f;
          box-shadow: 0 20px 40px #402f6f;
}

.admin-container button,
.admin-container a {
  line-height: 40px;
  border-radius: 30px;
  cursor: pointer;
  width: 300px;
  border: none;
  background-color: #402f6f;
  color: white;
  font-weight: 500;
  font-size: 20px;
  margin-top: 42px;
}

.admin-container button:active, .admin-container button:hover,
.admin-container a:active,
.admin-container a:hover {
  text-shadow: 0 0 2px #fff;
}

.admin-container .admin-publish-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 960px) {
  .admin-container .admin-publish-buttons {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 50px;
  }
}

@media screen and (max-width: 960px) {
  .admin-container .admin-publish-buttons a {
    width: 70%;
  }
}

.admin-container .error-message-wrapper {
  color: red;
  font-weight: 700;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 42px;
}

.admin-container .photo-input {
  border: 1px solid rgba(64, 48, 111, 0.3);
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 42px;
  cursor: pointer;
  width: 300px;
  text-align: center;
  background-color: rgba(64, 48, 111, 0.3);
  font-weight: 500;
  font-size: 20px;
  margin-top: 42px;
}

.admin-container .photo-input:hover {
  background-color: rgba(64, 48, 111, 0.9);
  color: white;
  font-weight: 500;
}

.admin-container .photo-input input[type="file"] {
  display: none;
}

.admin-container .wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  padding: 10px;
  margin-bottom: 4%;
}

.admin-container .wrap .editor-wrapper {
  padding: 20px;
  border: 2px solid #402f6f9d;
  width: 90%;
  margin-top: 42px;
}

.admin-container .wrap .message-toolbar {
  border-bottom: 1px solid #c4cdd5;
  margin-bottom: 10px;
  padding: 10px 5px;
  width: 100%;
}

.admin-container .wrap .message-editor {
  min-height: 400px;
  font-size: 1.4em;
  padding: 10px 10px;
  background-color: white;
}

.admin-container .wrap .rdw-option-wrapper {
  min-width: 35px;
  height: 40px;
}

.admin-container .wrap .rdw-fontsize-wrapper {
  width: 15%;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.admin-container .wrap .rdw-fontsize-wrapper .rdw-dropdown-wrapper,
.admin-container .wrap .rdw-fontsize-wrapper .rdw-fontsize-dropdown {
  width: 100%;
  height: 40px;
}

.admin-container .wrap .rdw-fontsize-wrapper .rdw-dropdown-wrapper .rdw-dropdown-selectedtext,
.admin-container .wrap .rdw-fontsize-wrapper .rdw-fontsize-dropdown .rdw-dropdown-selectedtext {
  background-color: transparent;
  color: black;
  position: relative;
  width: 100%;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0;
}

.admin-container .wrap .rdw-fontsize-wrapper .rdw-dropdown-wrapper .rdw-dropdown-selectedtext img,
.admin-container .wrap .rdw-fontsize-wrapper .rdw-fontsize-dropdown .rdw-dropdown-selectedtext img {
  width: 10%;
}

.admin-container .wrap .rdw-fontsize-wrapper .rdw-dropdown-wrapper .rdw-dropdown-selectedtext .rdw-dropdown-carettoopen,
.admin-container .wrap .rdw-fontsize-wrapper .rdw-fontsize-dropdown .rdw-dropdown-selectedtext .rdw-dropdown-carettoopen {
  display: none;
}

.admin-container .wrap .rdw-fontsize-wrapper .rdw-dropdown-wrapper .rdw-dropdown-selectedtext .rdw-dropdown-carettoclose,
.admin-container .wrap .rdw-fontsize-wrapper .rdw-fontsize-dropdown .rdw-dropdown-selectedtext .rdw-dropdown-carettoclose {
  display: none;
}

.admin-container .previewImage {
  max-width: 50%;
  max-height: 50%;
}

.admin-container .sent-successufully-modal {
  position: fixed;
  z-index: 99;
  background-color: #402f6f;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.admin-container .sent-successufully-modal h2 {
  color: #fbba25;
  margin: 0;
  margin-right: 12px;
  font-size: 24px;
}

.admin-container .sent-successufully-modal .checkmark {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-animation: showCheckmark 1s forwards ease-in-out;
          animation: showCheckmark 1s forwards ease-in-out;
}

@-webkit-keyframes showCheckmark {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes showCheckmark {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.admin-container .validation-msg {
  position: absolute;
  top: 100%;
  right: 5%;
  color: red;
  font-size: 20px;
}

.admin-container .upload-photo-preview {
  max-width: 300px;
  max-height: 300px;
}

@media screen and (min-width: 1280px) {
  .admin-container .upload-photo-preview {
    max-width: 640px;
    max-height: 640px;
  }
}
