#press-clipping-container {
  width: 100%;
  background-color: #463c78;
  background-image: url("../../assets/background-lines.png");
  background-size: 100% 100%;
  min-height: 100vh;
  h1 {
    color: #fbbb25;
    font-size: 30px;
    font-weight: 800;
    text-transform: uppercase;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
    margin-bottom: 42px;
    @media only screen and (min-width: 1280px) {
      margin-bottom: 100px;
      font-size: 42px;
    }
  }

  .press-clipping-inner-wrapper {
    padding-top: 42px;
    .cards-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .card-wrapper {
        width: 300px;
        margin: 10px;
        margin-bottom: 20px;
        @media only screen and (min-width: 1280px) {
          width: 420px;
          margin: 20px;
          margin-bottom: 42px;
        }
        &:active,
        &:hover {
            img{
                filter: drop-shadow(0 0 5px #fbbb25);
            }
            p{
                text-shadow: 0px 0px 5px #fbbb25;
            }
        }
        img {
          width: 100%;
          height: 169px;
          margin-bottom: 24px;
          @media only screen and (min-width: 1280px) {
            margin-bottom: 32px;
            height: 233px;
          }
        }
        p {
          color: #fbbb25;
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
          text-align: justify;
          text-decoration: none;
          @media only screen and (min-width: 1280px) {
            font-size: 20px;
          }
        }
      }
    }
  }
}
