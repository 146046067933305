@import url("http://fonts.cdnfonts.com/css/geometria");

* {
  margin: 0;
  padding: 0;
  font-family: "Geometria", sans-serif;
  box-sizing: border-box;
}
body {
  padding-top: 99px;
}
@media only screen and (min-width: 1280px) {
  body {
    padding-top: 110px;
  }
}

.header-footer-shadow {
  width: 100%;
  height: 0;
  box-shadow: 0px 0px 6px 7px rgba(0,0,0,0.4);
  -webkit-box-shadow: 0px 0px 6px 7px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 0px 6px 7px rgba(0,0,0,0.4);
}

a{
  text-decoration: none;
  color: inherit;
  text-align: center;
}