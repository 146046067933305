.wrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //   background-color: #fbba24c2;

  /* .loader {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: rgba(132, 4, 139, 0.367);
    animation: wave 1.15s ease infinite;
  }
  
  @keyframes wave {
    30% {
      box-shadow: 0 0 0 70px rgba(62, 31, 57, 0.617), 0 0 0 30px #939b1c9a;
    }
    80% {
      box-shadow: 0 0 0 60px #64286f7d, 0 0 0 80px #848616a4;
    }
  } */

  /* .loader {
    width: calc(100px - 24px);
    height: 50px;
    position: relative;
    animation: flippx 2s infinite linear;
  }
  .loader:before {
    content: "";
    position: absolute;
    inset: 0;
    margin: auto;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgb(231, 252, 0);
    transform-origin: -24px 50%;
    animation: spin 1s infinite linear;
  }
  .loader:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(125, 5, 181, 0.779);
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
  
  @keyframes flippx {
    10%,
    39% {
      transform: scaleX(-1);
    }
    65%,
    100% {
      transform: scaleY(-1);
    }
  }
  @keyframes spin {
    95% {
      transform: rotate(180deg);
    }
  } */
  /* .loader {
    border: 24px solid #fff;
    border-radius: 50%;
    transform: rotate(60deg);
    animation: pieFill 3.5s ease-in infinite;
    height: 300px;
    width: 300px;
  }
  
  @keyframes pieFill {
    0%,
    19% {
      border-color: rgb(135, 15, 154) rgb(155, 8, 148) rgb(114, 23, 166)
        rgb(171, 39, 160);
    }
    20%,
    39% {
      border-color: #ff3d00 rgb(155, 11, 133) rgb(192, 9, 199) rgb(170, 15, 181);
    }
    40%,
    59% {
      border-color: #ff3d00 #ff3d00 #fff #fff;
    }
    60%,
    79% {
      border-color: #c916d9 #7a047a #991089 rgb(205, 107, 107);
    }
    80%,
    100% {
      border-color: #ff3d00 #ff3d00 #ff3d00 #ff3d00;
    }
  } */

  /* .loader {
    width: 200px;
    height: 200px;
    position: relative;
    animation: rotate 1.5s ease-in infinite alternate;
  }
  .loader::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    color: #40306f;
    background: currentColor;
    width: 200px;
    height: 100px;
    border-radius: 0 0 50px 50px;
  }
  .loader::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 10%;
    background: #fbba24;
    width: 32px;
    height: 140px;
    animation: rotate 1.2s linear infinite alternate-reverse;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  } */

  /* .loader {
    width: 124px;
    height: 124px;
    position: relative;
  }
  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 124px;
    height: 124px;
    border-radius: 50%;
    background: #fbba24;
    animation: push 1s infinite linear alternate;
  }
  .loader:after {
    top: auto;
    bottom: 0;
    left: 0;
    background: #40306f;
    animation-direction: alternate-reverse;
  }
  @keyframes push {
    0% {
      width: 34px;
      height: 34px;
    }
    100% {
      width: 124px;
      height: 124px;
    }
  } */

  /* .loader {
    box-sizing: border-box;
    position: relative;
    width: 126px;
    height: 126px;
    animation: spin 1s linear infinite;
  }
  .loader:after,
  .loader:before {
    content: "";
    width: 64px;
    height: 64px;
    position: absolute;
    border-radius: 50%;
    background: #40306f;
    animation: spin 1s linear infinite;
    transform-origin: 0px 100%;
  }
  .loader:before {
    transform-origin: 0 30%;
    background: #fbba24;
  }
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  } */

  /* .loader {
    animation: rotate 1.2s infinite;
    height: 50px;
    width: 50px;
  }
  
  .loader:before,
  .loader:after {
    border-radius: 50%;
    content: "";
    display: block;
    height: 50px;
    width: 50px;
  }
  .loader:before {
    animation: ball1 1.2s infinite;
    background-color: #40306f;
    box-shadow: 50px 20px 0 #fbba24;
    margin-bottom: 10px;
  }
  .loader:after {
    animation: ball2 1.2s infinite;
    background-color: #fbba24;
    box-shadow: 50px 20px 0 #40306f;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg) scale(0.7);
    }
    50% {
      transform: rotate(360deg) scale(1.2);
    }
    100% {
      transform: rotate(720deg) scale(0.7);
    }
  }
  
  @keyframes ball1 {
    0% {
      box-shadow: 40px 30px 0 #fbba24;
    }
    50% {
      box-shadow: 40px 40px 0 #40306f;
      margin-bottom: 0;
      transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 30px 0 #fbba24;
      margin-bottom: 10px;
    }
  }
  
  @keyframes ball2 {
    0% {
      box-shadow: 40px 30px 0 #40306f;
    }
    50% {
      box-shadow: 40px 40px 0 #fbba24;
      margin-top: -20px;
      transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 30px 0 #40306f;
      margin-top: 0;
    }
  } */

  /* .loader {
    width: 84px;
    height: 84px;
    display: inline-block;
    position: relative;
    border: 6px solid #40306f;
    box-sizing: border-box;
    animation: rotation 2.2s linear infinite;
  }
  .loader::after,
  .loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 6px solid #fbba24;
    width: 64px;
    height: 64px;
    animation: rotationBack 1.6s linear infinite;
    transform-origin: center center;
  }
  .loader::before {
    width: 36px;
    height: 36px;
    border-color: #40306f;
    box-sizing: border-box;
    background-color: #fbba24ab;
    animation: rotation 1.2s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  } */

  /* ovaj je dobar */
  .loader {
    position: relative;
    width: 200px;
    height: 200px;
  }
  .loader::before,
  .loader::after {
    content: "";
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: #40306f;
    left: 50%;
    top: 50%;
    animation: rotate 1s ease-in infinite;
  }
  .loader::after {
    width: 32px;
    height: 32px;
    background-color: #fbba24;
    animation: rotate 1s ease-in infinite, moveY 1s ease-in infinite;
  }

  @keyframes moveY {
    0%,
    100% {
      top: 10%;
    }
    45%,
    55% {
      top: 59%;
    }
    60% {
      top: 40%;
    }
  }
  @keyframes rotate {
    0% {
      transform: translate(-50%, -100%) rotate(0deg) scale(1, 1);
    }
    25% {
      transform: translate(-50%, 0%) rotate(180deg) scale(1, 1);
    }
    45%,
    55% {
      transform: translate(-50%, 100%) rotate(180deg) scale(3, 0.5);
    }
    60% {
      transform: translate(-50%, 100%) rotate(180deg) scale(1, 1);
    }
    75% {
      transform: translate(-50%, 0%) rotate(270deg) scale(1, 1);
    }
    100% {
      transform: translate(-50%, -100%) rotate(360deg) scale(1, 1);
    }
  }

  /* .loader {
      display: block;
      position: relative;
      height: 44px;
      width: 200px;
      background: #40306f;
      border: 2px solid #fbba24;
      overflow: hidden;
    }
    .loader::before {
      content: "";
      background: #fbba24;
      color: #40306f;
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      animation: loading 8s linear infinite;
    }
    .loader:after {
      content: "";
      position: absolute;
      left: 8px;
      top: 4px;
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 32px;
      line-height: 32px;
      color: #fbba24;
      mix-blend-mode: difference;
      animation: percentage 8s linear infinite;
    }
    
    @keyframes loading {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
    @keyframes percentage {
      0% {
        content: "0%";
      }
      5% {
        content: "5%";
      }
      10% {
        content: "10%";
      }
      20% {
        content: "20%";
      }
      30% {
        content: "30%";
      }
      40% {
        content: "40%";
      }
      50% {
        content: "50%";
      }
      60% {
        content: "60%";
      }
      70% {
        content: "70%";
      }
      80% {
        content: "80%";
      }
      90% {
        content: "90%";
      }
      95% {
        content: "95%";
      }
      96% {
        content: "96%";
      }
      97% {
        content: "97%";
      }
      98% {
        content: "98%";
      }
      99% {
        content: "99%";
      }
      100% {
        content: "100%";
      }
    } */

  /* .loader {
      color: #40306f;
      position: relative;
      display: inline-block;
      margin-top: 40px;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 48px;
      font-weight: 500;
      letter-spacing: 4px;
      box-sizing: border-box;
    }
    .loader::before {
      content: "";
      position: absolute;
      right: 70.5px;
      bottom: 12px;
      height: 32px;
      width: 5px;
      background: currentColor;
      box-sizing: border-box;
      animation: animloader1 1s linear infinite alternate;
    }
    .loader::after {
      content: "";
      width: 15px;
      height: 15px;
      position: absolute;
      left: 125px;
      top: 2px;
      border-radius: 50%;
      background: #fbba24;
      box-sizing: border-box;
      animation: animloader 1s linear infinite alternate;
    }
    
    @keyframes animloader {
      0% {
        transform: translate(0px, 0px) scaleX(1);
      }
      14% {
        transform: translate(-12px, -16px) scaleX(1.05);
      }
      28% {
        transform: translate(-27px, -28px) scaleX(1.07);
      }
      42% {
        transform: translate(-46px, -35px) scaleX(1.1);
      }
      57% {
        transform: translate(-70px, -37px) scaleX(1.1);
      }
      71% {
        transform: translate(-94px, -32px) scaleX(1.07);
      }
      85% {
        transform: translate(-111px, -22px) scaleX(1.05);
      }
      100% {
        transform: translate(-125px, -9px) scaleX(1);
      }
    }
    
    @keyframes animloader1 {
      0% {
        box-shadow: 0 -6px, -122.9px -8px;
      }
      25%,
      75% {
        box-shadow: 0 0px, -122.9px -8px;
      }
      100% {
        box-shadow: 0 0px, -122.9px -16px;
      }
    } */

  /* .loader {
      width: 180px;
      height: 180px;
      background-color: #40306feb;
      border-radius: 50%;
      position: relative;
      box-shadow: 0 0 30px 4px rgba(0, 0, 0, 0.5) inset,
        0 5px 12px rgba(0, 0, 0, 0.15);
      overflow: hidden;
      border: none;
    }
    .loader:before,
    .loader:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      top: -40%;
      background-color: #fbba24ef;
      animation: wave 5s linear infinite;
    }
    .loader:before {
      border-radius: 30%;
      background: rgba(255, 255, 255, 0.4);
      animation: wave 5s linear infinite;
    }
    @keyframes wave {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    } */

  /* .loader {
      width: 106px;
      height: 56px;
      display: block;
      margin: 30px auto;
      background-image: linear-gradient(rgb(255, 0, 255) 50px, transparent 0),
        linear-gradient(rgb(176, 18, 126) 50px, transparent 0),
        linear-gradient(rgb(201, 69, 188) 50px, transparent 0),
        linear-gradient(rgb(109, 4, 102) 50px, transparent 0),
        radial-gradient(circle 14px, rgb(180, 18, 164) 100%, transparent 0);
      background-size: 48px 15px, 15px 35px, 15px 35px, 25px 15px, 28px 28px;
      background-position: 25px 5px, 58px 20px, 25px 17px, 2px 37px, 76px 0px;
      background-repeat: no-repeat;
      position: relative;
      transform: rotate(-45deg);
      box-sizing: border-box;
    }
    .loader::after,
    .loader::before {
      content: "";
      position: absolute;
      width: 56px;
      height: 56px;
      border: 6px solid rgb(187, 0, 255);
      border-radius: 50%;
      left: -45px;
      top: -10px;
      background-repeat: no-repeat;
      background-image: linear-gradient(#fff 64px, transparent 0),
        linear-gradient(#fff 66px, transparent 0),
        radial-gradient(circle 4px, #fff 100%, transparent 0);
      background-size: 40px 1px, 1px 40px, 8px 8px;
      background-position: center center;
      box-sizing: border-box;
      animation: rotation 0.3s linear infinite;
    }
    .loader::before {
      left: 25px;
      top: 60px;
    }
    
    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    } */

  /* .loader {
      position: relative;
      width: 130px;
      height: 100px;
      background-repeat: no-repeat;
      background-image: linear-gradient(#fbba24ef, #40306feb),
        linear-gradient(#fbba24ef, #40306feb), linear-gradient(#40306feb, #fbba24ef);
      background-size: 80px 70px, 30px 50px, 30px 30px;
      background-position: 0 0, 80px 20px, 100px 40px;
    }
    .loader:after {
      content: "";
      position: absolute;
      bottom: 10px;
      left: 12px;
      width: 10px;
      height: 10px;
      background: #fff;
      border-radius: 50%;
      box-sizing: content-box;
      border: 10px solid #000;
      box-shadow: 78px 0 0 -10px #fff, 78px 0 #000;
      animation: wheelSk 0.75s ease-in infinite alternate;
    }
    
    .loader:before {
      content: "";
      position: absolute;
      right: 100%;
      top: 0px;
      height: 70px;
      width: 70px;
      background-image: linear-gradient(#fff 45px, transparent 0),
        linear-gradient(#fff 45px, transparent 0),
        linear-gradient(#fff 45px, transparent 0);
      background-repeat: no-repeat;
      background-size: 30px 4px;
      background-position: 0px 11px, 8px 35px, 0px 60px;
      animation: lineDropping 0.75s linear infinite;
    }
    
    @keyframes wheelSk {
      0%,
      50%,
      100% {
        transform: translatey(0);
      }
      30%,
      90% {
        transform: translatey(-3px);
      }
    }
    
    @keyframes lineDropping {
      0% {
        background-position: 100px 11px, 115px 35px, 105px 60px;
        opacity: 1;
      }
      50% {
        background-position: 0px 11px, 20px 35px, 5px 60px;
      }
      60% {
        background-position: -30px 11px, 0px 35px, -10px 60px;
      }
      75%,
      100% {
        background-position: -30px 11px, -30px 35px, -30px 60px;
        opacity: 0;
      }
    } */
}
