#header-container {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  .header-wrapper {
    height: 99px;
    width: 100%;
    background-color: #402f6f;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media only screen and (min-width: 1280px) {
      height: 110px;
    }

    .logo-wrapper {
      width: 50%;
      max-width: 240px;
      margin-top: 10px;
      margin-left: 25px;
      margin-bottom: 10px;
      img {
        width: 100%;
        height: auto;
      }
    }

    .header-links-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 100%;
      background-color: #402f6f;
      padding-top: 24px;
      padding-bottom: 24px;
      transform: scale(0);
      opacity: 0;
      @media only screen and (min-width: 1280px) {
        transform: scale(1);
        opacity: 1;
        flex-direction: row;
        position: static;
        justify-content: unset;
        width: auto;
      }

      p {
        color: #fbbb25;
        line-height: 42px;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 600;

        &:active,
        &:hover {
          cursor: pointer;
          color: #fbbb25;
        }
        @media only screen and (min-width: 1280px) {
          margin-right: 42px;
          color: #fff;
          font-weight: 500;
        }
      }
    }
    .show-mobile-menu {
      animation: showMobileMenu 1s forwards;
    }

    @keyframes showMobileMenu {
      from {
        transform: scale(0);
        opacity: 0;
      }

      to {
        transform: scale(1);
        opacity: 1;
      }
    }

    .hamburger-menu-btn {
      width: 42px;
      height: 27px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 25px;
      &:active,
      &:hover {
        cursor: pointer;
        filter: drop-shadow(0 0 5px #fff);
      }
      @media only screen and (min-width: 1280px) {
        display: none;
      }
      .hamburger-menu-btn-line {
        width: 100%;
        height: 2px;
        background-color: #fff;
      }
      .hide-it {
        display: none;
      }
      .rotate-down {
        transform: rotate(45deg);
        position: relative;
        top: 15px;
      }
      .rotate-up {
        transform: rotate(-45deg);
        position: relative;
        bottom: 10px;
      }
    }
  }
  .admin-horizontal-line {
    width: 66%;
    height: 2px;
    margin: 0 auto;
    background-color: #fbbb25;
    @media only screen and (min-width: 1280px) {
      display: none;
    }
  }
}
