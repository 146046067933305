#single-clipping-container {
  width: 100%;
  background-color: #463c78;
  background-image: url("../../assets/background-lines.png");
  background-size: 100% 100%;
  min-height: calc(100vh - 280px);
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: 1280px) {
    min-height: calc(100vh - 290px);
  }
  h1 {
    color: #fbbb25;
    font-size: 30px;
    font-weight: 800;
    text-transform: uppercase;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
    margin-bottom: 42px;
    padding-top: 32px;
    @media only screen and (min-width: 1280px) {
      margin-bottom: 100px;
      font-size: 42px;
    }
  }
  .kratki-opis {
    width: 100%;
    max-width: 720px;
    font-size: 20px;
    font-weight: 300;
    color: #fff;
    @media only screen and (min-width: 1280px) {
      text-align: justify;
    }
  }
  img {
    width: 100%;
    max-width: 640px;
    height: auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .rich-text-wrapper {
    width: 100%;
    max-width: 960px;
    font-size: 20px;
    font-weight: 300;
    margin: 0 auto;
    color: #fff;
    margin-bottom: 120px;
    @media only screen and (min-width: 1280px) {
      text-align: justify;
    }
  }
}
