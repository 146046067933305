#home-container {
  .section-1-container {
    width: 100%;
    background-color: #fbba25;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (min-width: 1280px) {
      flex-direction: row;
      justify-content: space-evenly;
      align-items: unset;
    }

    img {
      width: 79%;
      max-width: 420px;
      margin-top: 42px;
      margin-bottom: 42px;
      @media only screen and (min-width: 1280px) {
        width: 55%;
        max-width: unset;
        align-self: flex-end;
        margin-top: 200px;
      }
    }

    .section-1-txt-wrapper {
      width: 68%;
      max-width: 420px;
      margin-top: 42px;
      margin-bottom: 42px;
      @media only screen and (min-width: 1280px) {
        max-width: 320px;
      }
      h1 {
        color: #402f6f;
        font-size: 30px;
        font-weight: 800;
        text-transform: uppercase;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: center;
        margin-bottom: 20px;
      }
      p {
        color: #402f6f;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: justify;
      }
    }
  }

  .section-2-container {
    width: 100%;
    background-color: #402f6f;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (min-width: 1280px) {
      flex-direction: row-reverse;
      justify-content: space-evenly;
    }

    img {
      width: 79%;
      max-width: 420px;
      margin-top: 42px;
      margin-bottom: 42px;
    }

    .announcing-container {
      display: flex;
      flex-direction: column;
      .card-wrapper {
        max-width: 420px;
        height: 340px;
        margin-top: 42px;
        margin-bottom: 42px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background-color: #fbba25;
        border-radius: 32px;
        padding: 20px;

        h2 {
          color: #402f6f;
          font-size: 22px;
          font-weight: 800;
          text-transform: uppercase;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
          text-align: center;
          margin-bottom: 20px;
        }
        p {
          color: #402f6f;
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
        }
        .btn {
            background-color: #402f6f;
            width: 240px;
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: normal;
            line-height: 40px;
            text-align: center;
            border-radius: 20px;
            &:active,
            &:hover{
                cursor: pointer;
                text-decoration: underline;
            }
        }
      }
    }
  }
}
