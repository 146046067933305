#footer-container .social-media-wrapper {
  width: 100%;
  background-color: #463c78;
  padding-top: 90px;
  padding-bottom: 30px;
  position: relative;
}

#footer-container .social-media-wrapper .social-media-inner-wrapper {
  position: absolute;
  right: 42px;
  bottom: 20px;
}

#footer-container .social-media-wrapper a {
  display: inline-block;
}

#footer-container .social-media-wrapper .facebook-icon {
  margin-left: auto;
}

#footer-container .social-media-wrapper img {
  width: 30px;
  height: auto;
  margin-right: 10px;
}

#footer-container .social-media-wrapper img:active, #footer-container .social-media-wrapper img:hover {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 0 5px #fff);
          filter: drop-shadow(0 0 5px #fff);
}

#footer-container .copyright {
  width: 100%;
  background-color: #402f6f;
  font-size: 14px;
  font-weight: 500;
  line-height: 60px;
  text-align: center;
  color: #fff;
  margin: 0;
}
