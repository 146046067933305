#single-clipping-container {
  width: 100%;
  background-color: #463c78;
  background-image: url("../../assets/background-lines.png");
  background-size: 100% 100%;
  min-height: calc(100vh - 280px);
  padding-left: 25px;
  padding-right: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (min-width: 1280px) {
  #single-clipping-container {
    min-height: calc(100vh - 290px);
  }
}

#single-clipping-container h1 {
  color: #fbbb25;
  font-size: 30px;
  font-weight: 800;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  margin-bottom: 42px;
  padding-top: 32px;
}

@media only screen and (min-width: 1280px) {
  #single-clipping-container h1 {
    margin-bottom: 100px;
    font-size: 42px;
  }
}

#single-clipping-container .kratki-opis {
  width: 100%;
  max-width: 720px;
  font-size: 20px;
  font-weight: 300;
  color: #fff;
}

@media only screen and (min-width: 1280px) {
  #single-clipping-container .kratki-opis {
    text-align: justify;
  }
}

#single-clipping-container img {
  width: 100%;
  max-width: 640px;
  height: auto;
  margin-top: 100px;
  margin-bottom: 100px;
}

#single-clipping-container .rich-text-wrapper {
  width: 100%;
  max-width: 960px;
  font-size: 20px;
  font-weight: 300;
  margin: 0 auto;
  color: #fff;
  margin-bottom: 120px;
}

@media only screen and (min-width: 1280px) {
  #single-clipping-container .rich-text-wrapper {
    text-align: justify;
  }
}
