#home-container .section-1-container {
  width: 100%;
  background-color: #fbba25;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (min-width: 1280px) {
  #home-container .section-1-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-align: unset;
        -ms-flex-align: unset;
            align-items: unset;
  }
}

#home-container .section-1-container img {
  width: 79%;
  max-width: 420px;
  margin-top: 42px;
  margin-bottom: 42px;
}

@media only screen and (min-width: 1280px) {
  #home-container .section-1-container img {
    width: 55%;
    max-width: unset;
    -ms-flex-item-align: end;
        align-self: flex-end;
    margin-top: 200px;
  }
}

#home-container .section-1-container .section-1-txt-wrapper {
  width: 68%;
  max-width: 420px;
  margin-top: 42px;
  margin-bottom: 42px;
}

@media only screen and (min-width: 1280px) {
  #home-container .section-1-container .section-1-txt-wrapper {
    max-width: 320px;
  }
}

#home-container .section-1-container .section-1-txt-wrapper h1 {
  color: #402f6f;
  font-size: 30px;
  font-weight: 800;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  margin-bottom: 20px;
}

#home-container .section-1-container .section-1-txt-wrapper p {
  color: #402f6f;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: justify;
}

#home-container .section-2-container {
  width: 100%;
  background-color: #402f6f;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (min-width: 1280px) {
  #home-container .section-2-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }
}

#home-container .section-2-container img {
  width: 79%;
  max-width: 420px;
  margin-top: 42px;
  margin-bottom: 42px;
}

#home-container .section-2-container .announcing-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

#home-container .section-2-container .announcing-container .card-wrapper {
  max-width: 420px;
  height: 340px;
  margin-top: 42px;
  margin-bottom: 42px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  background-color: #fbba25;
  border-radius: 32px;
  padding: 20px;
}

#home-container .section-2-container .announcing-container .card-wrapper h2 {
  color: #402f6f;
  font-size: 22px;
  font-weight: 800;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  margin-bottom: 20px;
}

#home-container .section-2-container .announcing-container .card-wrapper p {
  color: #402f6f;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

#home-container .section-2-container .announcing-container .card-wrapper .btn {
  background-color: #402f6f;
  width: 240px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 40px;
  text-align: center;
  border-radius: 20px;
}

#home-container .section-2-container .announcing-container .card-wrapper .btn:active, #home-container .section-2-container .announcing-container .card-wrapper .btn:hover {
  cursor: pointer;
  text-decoration: underline;
}
