#press-clipping-container {
  width: 100%;
  background-color: #463c78;
  background-image: url("../../assets/background-lines.png");
  background-size: 100% 100%;
  min-height: 100vh;
}

#press-clipping-container h1 {
  color: #fbbb25;
  font-size: 30px;
  font-weight: 800;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  margin-bottom: 42px;
}

@media only screen and (min-width: 1280px) {
  #press-clipping-container h1 {
    margin-bottom: 100px;
    font-size: 42px;
  }
}

#press-clipping-container .press-clipping-inner-wrapper {
  padding-top: 42px;
}

#press-clipping-container .press-clipping-inner-wrapper .cards-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#press-clipping-container .press-clipping-inner-wrapper .cards-container .card-wrapper {
  width: 300px;
  margin: 10px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1280px) {
  #press-clipping-container .press-clipping-inner-wrapper .cards-container .card-wrapper {
    width: 420px;
    margin: 20px;
    margin-bottom: 42px;
  }
}

#press-clipping-container .press-clipping-inner-wrapper .cards-container .card-wrapper:active img, #press-clipping-container .press-clipping-inner-wrapper .cards-container .card-wrapper:hover img {
  -webkit-filter: drop-shadow(0 0 5px #fbbb25);
          filter: drop-shadow(0 0 5px #fbbb25);
}

#press-clipping-container .press-clipping-inner-wrapper .cards-container .card-wrapper:active p, #press-clipping-container .press-clipping-inner-wrapper .cards-container .card-wrapper:hover p {
  text-shadow: 0px 0px 5px #fbbb25;
}

#press-clipping-container .press-clipping-inner-wrapper .cards-container .card-wrapper img {
  width: 100%;
  height: 169px;
  margin-bottom: 24px;
}

@media only screen and (min-width: 1280px) {
  #press-clipping-container .press-clipping-inner-wrapper .cards-container .card-wrapper img {
    margin-bottom: 32px;
    height: 233px;
  }
}

#press-clipping-container .press-clipping-inner-wrapper .cards-container .card-wrapper p {
  color: #fbbb25;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: justify;
  text-decoration: none;
}

@media only screen and (min-width: 1280px) {
  #press-clipping-container .press-clipping-inner-wrapper .cards-container .card-wrapper p {
    font-size: 20px;
  }
}
