#o-nama-container {
  background-color: #463c78;
}

#o-nama-container .section-1-container {
  padding: 42px 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#o-nama-container .section-1-container p {
  max-width: 840px;
}

#o-nama-container .section-2-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (min-width: 1280px) {
  #o-nama-container .section-2-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}

#o-nama-container .section-2-container img {
  width: 79%;
  max-width: 500px;
  margin-top: 42px;
  margin-bottom: 42px;
}

#o-nama-container .section-3-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 100px;
}

@media only screen and (min-width: 1280px) {
  #o-nama-container .section-3-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}

#o-nama-container .section-3-container img {
  width: 79%;
  max-width: 420px;
  margin-top: 42px;
  margin-bottom: 42px;
}

#o-nama-container .txt-wrapper {
  width: 68%;
  max-width: 420px;
  margin-top: 42px;
  margin-bottom: 42px;
}

@media only screen and (min-width: 1280px) {
  #o-nama-container .txt-wrapper {
    max-width: 500px;
  }
}

#o-nama-container h1,
#o-nama-container h2 {
  color: #fabc06;
  font-size: 30px;
  font-weight: 800;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  margin-bottom: 25px;
}

#o-nama-container h2 {
  font-size: 25px;
}

#o-nama-container p {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: justify;
}
