#header-container {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

#header-container .header-wrapper {
  height: 99px;
  width: 100%;
  background-color: #402f6f;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
}

@media only screen and (min-width: 1280px) {
  #header-container .header-wrapper {
    height: 110px;
  }
}

#header-container .header-wrapper .logo-wrapper {
  width: 50%;
  max-width: 240px;
  margin-top: 10px;
  margin-left: 25px;
  margin-bottom: 10px;
}

#header-container .header-wrapper .logo-wrapper img {
  width: 100%;
  height: auto;
}

#header-container .header-wrapper .header-links-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: absolute;
  top: 100%;
  background-color: #402f6f;
  padding-top: 24px;
  padding-bottom: 24px;
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
}

@media only screen and (min-width: 1280px) {
  #header-container .header-wrapper .header-links-wrapper {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    position: static;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
    width: auto;
  }
}

#header-container .header-wrapper .header-links-wrapper p {
  color: #fbbb25;
  line-height: 42px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
}

#header-container .header-wrapper .header-links-wrapper p:active, #header-container .header-wrapper .header-links-wrapper p:hover {
  cursor: pointer;
  color: #fbbb25;
}

@media only screen and (min-width: 1280px) {
  #header-container .header-wrapper .header-links-wrapper p {
    margin-right: 42px;
    color: #fff;
    font-weight: 500;
  }
}

#header-container .header-wrapper .show-mobile-menu {
  -webkit-animation: showMobileMenu 1s forwards;
          animation: showMobileMenu 1s forwards;
}

@-webkit-keyframes showMobileMenu {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@keyframes showMobileMenu {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

#header-container .header-wrapper .hamburger-menu-btn {
  width: 42px;
  height: 27px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-right: 25px;
}

#header-container .header-wrapper .hamburger-menu-btn:active, #header-container .header-wrapper .hamburger-menu-btn:hover {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 0 5px #fff);
          filter: drop-shadow(0 0 5px #fff);
}

@media only screen and (min-width: 1280px) {
  #header-container .header-wrapper .hamburger-menu-btn {
    display: none;
  }
}

#header-container .header-wrapper .hamburger-menu-btn .hamburger-menu-btn-line {
  width: 100%;
  height: 2px;
  background-color: #fff;
}

#header-container .header-wrapper .hamburger-menu-btn .hide-it {
  display: none;
}

#header-container .header-wrapper .hamburger-menu-btn .rotate-down {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  position: relative;
  top: 15px;
}

#header-container .header-wrapper .hamburger-menu-btn .rotate-up {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  position: relative;
  bottom: 10px;
}

#header-container .admin-horizontal-line {
  width: 66%;
  height: 2px;
  margin: 0 auto;
  background-color: #fbbb25;
}

@media only screen and (min-width: 1280px) {
  #header-container .admin-horizontal-line {
    display: none;
  }
}
